import React from "react";
import world from '../data/world';
import worldArray from '../data/worldArray';
import phoneCode from '../data/phone';

export const renderInput = (formProps) => {
  return (
    <>
      <input {...formProps.input} {...formProps}/>
      {formProps.meta.touched && formProps.meta.error && <span className="form-error">{formProps.meta.error}</span>}
    </>
  );
};

export const renderDate = (formProps) => {
  const {visited} = formProps.meta;
  return (
    <>
      <input type={(visited) ? 'date' : 'text'} {...formProps.input} {...formProps}/>
    </>
  );
};

export const renderSelect = (formProps) => {
  return (
    <select {...formProps.input} {...formProps}>
      {formProps.children}
    </select>
  );
};



export const getMaxBirthDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear()-16);
  const day = pad(date.getDate(), 2);
  const month = pad(date.getMonth() + 1, 2);
  const year = date.getFullYear();
  return  year + "-" + month + "-" + day;
};

const pad = (num, size) => {
  let s = num+"";
  while (s.length < size) s = "0" + s;
  return s;
};

export const worldCountries = () => {
  return Object.keys(world).map((key, index) => {
    return <option key={key} value={world[key].COUNTRY_ALPHA2_CODE}>{world[key].COUNTRY_NAME}</option>
  });
};

export const getWorldCountries = () => {
  return worldArray.map(country => {
    return {
      label: country.COUNTRY_NAME,
      value: country.COUNTRY_NAME,
      phoneCode: getPhoneCode(country.COUNTRY_ALPHA2_CODE),
    }
  });
};

export const getPhoneCode = (country) => {
  return phoneCode[country];
};

export const getPhoneCodes = () => {
  return Object.keys(phoneCode).map((key, index) => {
    return <option key={key} value={phoneCode[key]}>{phoneCode[key]}</option>
  });
};

export const getCountryName = (countryCode) => {
  return world[countryCode].COUNTRY_NAME;
};

export const allowOnlyNumber = (e) => {
  const numbersReg = /^[0-9\b]+$/;
  const typedChar = e.nativeEvent.data;
  if (typedChar !== undefined && typedChar !== null && !numbersReg.test(typedChar)){
    e.preventDefault();
  }
};

export const allowOnlyLetters = (e) => {
  const numbersReg = /^[a-zàâçéèêëîïôûùüÿñæœ .-]*$/i;
  const typedChar = e.nativeEvent.data;
  if (typedChar !== undefined && typedChar !== null && !numbersReg.test(typedChar)){
    e.preventDefault();
  }
};

export const getParameterByName = (name) => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(window.location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
};


// export const renderDatePicker = (field) => {
//   const { meta: { touched, error } } = field;
//   return (
//     <Form.Field>
//       <label>{field.label}</label>
//       <DayPickerInput
//         {...field.input}
//         onDayChange={(day) => console.log(day)} />
//       {touched && error && <span>{error}</span>}
//     </Form.Field>
//   );
// };
