import React, {Component} from 'react';
import axios from 'axios';
import TagManager from 'react-gtm-module'
import FormFirstStep from "./FormFirstStep";
import FormSecondStep from "./FormSecondStep";
import ProgressBar from "./ProgressBar";
import Modal from 'react-modal';
import checkImg from "../../images/icons/verified.png";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import crm from "../../api/crm";
import _ from "lodash";
import FormThirdStep from "./FormThirdStep";
import {connect} from "react-redux";
import {getParameterByName} from "../../helpers/formHelpers";
import brochure from '../../images/brochure.pdf';

Modal.setAppElement('#root');

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFF'
    }
};

class AdmissionForm extends Component {
    state = {
        lastStep: 1,
        wsLastStep: null,
        page: 1,
        modalIsOpen: false,
        submitting: false,
        direction: 'right',
        ip: null,
        registrationId: null
    };

    componentDidMount() {
        const _this = this;
        window.onbeforeunload = function (e) {
            if (Object.keys(_this.props.formValues).length > 2 && _this.state.modalIsOpen === false) {
                return 'You have unsaved changes!';
            }
            return null;
        };
        const tagManagerArgs = {
            gtmId: 'GTM-PSXLM26'
        };

        TagManager.initialize(tagManagerArgs);
        this.fetchCloudProperties();
        this.hadnleUrlId();
    }

    async fetchCloudProperties() {
        const response = await axios.get("https://api.ipify.org/?format=json");
        if (response.data) {
            this.setState({ip: response.data.ip});
        }
    }

    sendValues = async (formValues) => {
        let updatedFormValues = {...formValues};
        updatedFormValues.country = 'Maroc';
        updatedFormValues.kind = 0;
        updatedFormValues.university_id = 32;
        updatedFormValues.desired_school = null;
        updatedFormValues.step = this.state.lastStep;
        updatedFormValues.ip = this.state.ip;
        updatedFormValues.utm_compaign = getParameterByName("utm_campaign");
        updatedFormValues.utm_source = getParameterByName("utm_source");
        updatedFormValues.utm_medium = getParameterByName("utm_medium");
        updatedFormValues.utm_content = getParameterByName("utm_content");
        updatedFormValues.source = "LP";
        if (updatedFormValues.test_admission) {
            const d = updatedFormValues.test_admission;
            const date = d.getDate();
            const month = d.getMonth() + 1;
            const year = d.getFullYear();

            const dateStr = year + "-" + month + "-" + date;
            updatedFormValues.test_admission = `${dateStr} ${updatedFormValues.test_admission_time}`;
            delete updatedFormValues.test_admission_time;
        }
        // handle phone format
        if (updatedFormValues.phone && updatedFormValues.phone_code) {
            updatedFormValues.phone = `${updatedFormValues.phone_code}${updatedFormValues.phone}`;
            delete updatedFormValues.phone_code;
        }
        if (updatedFormValues.first_parent_phone && updatedFormValues.phone_code_parent) {
            updatedFormValues.first_parent_phone = `${updatedFormValues.phone_code_parent}${updatedFormValues.first_parent_phone}`;
        }
        if (!this.state.registrationId) {
            const response = await crm.post('/registration/create', updatedFormValues).catch(function (error) {
                console.log(error);
            });
            this.setState({registrationId: response.data.RegistrationID});
        } else {
            let values = updatedFormValues;
            values.id = this.state.registrationId;
            await crm.post('/registration/update', values).catch(function (error) {
                console.log(error);
            });
        }
    };

    nextPage = async (e) => {
        e.preventDefault();
        this.setState({submitting: true});
        if (this.state.lastStep < this.state.page + 1) {
            this.setState({lastStep: this.state.page + 1});
        }
        await this.sendValues(this.props.formValues);
        this.sendDataLayer();
        this.setState({submitting: false, page: this.state.page + 1, direction: 'right'});
    };

    openModal = () => {
        this.setState({modalIsOpen: true});
    };

    closeModal = () => {
        this.setState({modalIsOpen: false});
        window.location.reload();
    };

    previousPage = () => {
        this.setState({page: this.state.page - 1, direction: 'left'});
    };

    onSubmit = async (formValues) => {
        this.setState({submitting: true});
        await this.sendValues(formValues);
        this.setState({submitting: false});
        this.sendDataLayer();
        this.openModal();
        this.downloadFile(brochure);
    };

    downloadFile = (file) => {
        const element = document.createElement('a');
        element.setAttribute('href', file);
        element.setAttribute('download', file);

        element.style.display = 'none';

        document.body.appendChild(element);

        element.click();
        document.body.removeChild(element);
    }

    sendDataLayer = () => {
        const page = this.state.page;
        let event = '';
        switch (page) {
            case 1:
                event = 'E01_formulaire_eleve';
                break;
            case 2:
                event = 'E02_formulaire_parent';
                break;
            case 3:
                event = 'E03_prise_contact';
                break;
            default:
                event = 'E01_formulaire_eleve'
        }

        const stepFields = {
            1: ['last_name', 'first_name', 'email', 'high_school_name',
                'high_school_type', 'high_school_city', 'niveau', 'phone_code', 'phone'],
            2: ['first_parent_civility', 'first_parent_last_name',
                'first_parent_first_name', 'first_parent_email',
                'phone_code_parent', 'first_parent_phone'],
            3: ['test_admission', 'contact_canal'],
        };

        const fieldsNames = stepFields[page];

        //Get current step fields values
        let data = this.mapDatalayerFields(page, fieldsNames);

        // Map through values to handle enum fields and objects values (phone, country...)
        this.mapDataLayerValues(data);

        // Change fields names to match DataLayer Plan
        this.mapDataLayerFieldsNames(data);

        // Add other values to data
        if (this.state.registrationId) {
            data.user_id = this.state.registrationId;
        }

        data = {...data, event: event};

        TagManager.dataLayer({dataLayer: data});
    };

    mapDataLayerFieldsNames = (data) => {
        const tagManagerFields = {
            "last_name": "nom",
            "first_name": "prenom",
            "email": "email",
            "high_school_name": "lycee",
            "high_school_type": "type_lycee",
            "high_school_city": "ville_lycee",
            "niveau": "niveau",
            "phone_code": "phone_code",
            "phone": "phone",
            "first_parent_civility": "civilite_parent",
            "first_parent_last_name": "nom_parent",
            "first_parent_first_name": "prenom_parent",
            "first_parent_email": "email_parent",
            "phone_code_parent": "code_phone_parent",
            "first_parent_phone": "phone_parent",
            "test_admission": "date_contact",
            "contact_canal": "methode_contact",
        };
        for (const [key, value] of Object.entries(data)) {
            if (tagManagerFields.hasOwnProperty(key)) {
                let fieldName = tagManagerFields[key];
                data[fieldName] = value;
            }
        }
        delete data.last_name;
        delete data.first_name;
        delete data.high_school_name;
        delete data.high_school_type;
        delete data.high_school_city;
        delete data.first_parent_civility;
        delete data.first_parent_last_name;
        delete data.first_parent_first_name;
        delete data.first_parent_email;
        delete data.phone_code_parent;
        delete data.first_parent_phone;
        delete data.test_admission;
        delete data.contact_canal;
        delete data.phone_code;
        delete data.code_phone_parent;
    };

    mapDatalayerFields = (step, fieldsNames) => {
        let data = {};
        if (!_.isEmpty(fieldsNames)) {
            fieldsNames.map(name => {
                if (name === "country") {
                    data["country"] = this.props.formValues[name];
                } else {
                    data[name] = this.props.formValues[name];
                }
            });
        }
        return data;
    };

    mapDataLayerValues = (data) => {
        const fieldsValue = {
            high_school_type: {
                0: 'Public',
                1: 'Privé',
                2: 'Mission'
            },
            contact_canal: {
                1: 'Par e-mail',
                2: 'Par Téléphone',
            },
        };
        for (const [key, value] of Object.entries(data)) {
            //handle phone value
            if (key === "phone") {
                data.phone = `${data.phone_code}${data.phone}`;
            }
            if (key === "first_parent_phone") {
                data.phone_parent = `${data.phone_code_parent}${data.first_parent_phone}`;
            } else if (fieldsValue.hasOwnProperty(key)) {
                data[key] = fieldsValue[key][value];
            }
        }
    };

    renderLoading = () => {
        const {submitting} = this.state;
        if (submitting) {
            return (
                <div className="steps-container--overlay">
                    <Loader
                        type="Circles"
                        color="rgba(253, 96, 32, 0.9)"
                        height={80}
                        width={80}/>
                </div>
            )
        }
    };


    render() {
        const {page} = this.state;
        return (
            <div className="form-container">
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Postuler">
                    <div className="c-modal">
                        <img src={checkImg} alt="success"/>
                        <h4>MERCI !</h4>
                        <p>Votre demande a bien été confirmée, un conseiller pédagogique prendra contact avec vous dans les plus brefs délais.</p>
                        <button onClick={this.closeModal} className="btn purple">Terminer</button>
                    </div>
                </Modal>
                {this.renderLoading()}
                <ProgressBar step={page}/>
                <div>
                    {page === 1 && <FormSecondStep initialValues={{phone_code: "+212", phone_code_parent: "+212"}} direction={this.state.direction}
                                                   handleSubmit={this.nextPage}/>}
                    {page === 2 &&
                    <FormFirstStep previousPage={this.previousPage}
                                   direction={this.state.direction} handleSubmit={this.nextPage}/>}
                    {page === 3 && <FormThirdStep direction={this.state.direction} previousPage={this.previousPage}
                                                  handleFormSubmit={this.onSubmit}/>}
                </div>
            </div>
        )
    }

    hadnleUrlId = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const registrationId = urlParams.get('mtid');
        if (!registrationId) {
            return;
        }
        this.setState({submitting: true});
        const response = await crm.get('/registration/get-lead', {
            params: {
                id: registrationId
            }
        }).catch(function (error) {
            console.log(error);
        });
        if (response.data.status === 200) {
            const step = response.data.registration.step;
            if (step < 5) {
                this.setState({
                    lastStep: response.data.registration.step + 1,
                    wsLastStep: response.data.registration.step,
                    registrationId: registrationId,
                    data: response.data.registration,
                    page: response.data.registration.step + 1
                });
            }
        }
        this.setState({submitting: false});
    }
}

const mapStateToProps = (state) => {
    const {admission_form} = state.form;
    if (typeof (admission_form) == 'undefined') {
        return {};
    }
    return {formValues: admission_form.values}
};
export default connect(
    mapStateToProps,
    null
)(AdmissionForm);
