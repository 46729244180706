import React from 'react';

const ProgressBar = ({step}) => {
  const activeClasses = 'step step--active';
  const completeClasses = 'step step--complete';
  const inactiveClasses = 'step step--incomplete step--inactive';

  return (
    <div className="steps-container">
      <ul className="steps">
        <li className={step > 1 ? completeClasses : activeClasses}>
          <span className="step__icon"/>
          <div className="step__label">
              Étape 1/3
          </div>
        </li>
        <li className={step > 2 ? completeClasses : inactiveClasses}>
          <span className="step__icon"/>
          <div className="step__label">
              Étape 2/3
          </div>
        </li>
        <li className={step > 3 ? completeClasses : inactiveClasses}>
          <span className="step__icon"/>
          <div className="step__label">
              Étape 3/3
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ProgressBar;
