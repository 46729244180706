import React from 'react';
import AdmissionForm from "./form/AdmissionForm";
import CoursePath from './CoursePath';

import '../styles/App.scss';
import LeftContent from "./LeftContent";
import VideoDescription from "./VideoDescription";

const App = () => {
  return (
    <div id="main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <LeftContent/>
          </div>
          <div className="col-md-8">
            <AdmissionForm/>
          </div>
        </div>
        <CoursePath />
        <VideoDescription />
      </div>
    </div>
  );
};

export default App;
