import React from 'react';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import validate from './validate';
import {allowOnlyLetters, allowOnlyNumber, getPhoneCodes, renderInput, renderSelect} from "../../helpers/formHelpers";
import FormHeader from "../FormHeader";
import {connect} from "react-redux";
import SlideAnimation from "../animation/SlideAnimation";
import AutocompleteInput from "./AutocompleteInput";


let FormSecondStep = (props) => {
  const {handleSubmit, direction} = props;

  return (
      <div>
        <FormHeader title="Formulaire élève"
                    description="Renseigner ce formulaire afin que nos conseillers pédagogiques prennent contact avec
                    vous. Complétez votre demande avec les informations sur votre enfant."/>
        <form onSubmit={handleSubmit} className="class">
          <div className="container small-container">
            <SlideAnimation direction={direction}>
              <div className="row">
                <div className="col-sm-6 form-group">
                  <Field className="form-control" component={renderInput} required name="last_name"
                         onChange={allowOnlyLetters} placeholder="Nom de l'élève*"/>
                </div>
                <div className="col-sm-6 form-group">
                  <Field className="form-control" component={renderInput} required name="first_name"
                         onChange={allowOnlyLetters} placeholder="Prénom de l'élève*"/>
                </div>
              </div>
              <div className="row">
                <div className="col form-group">
                  <Field className="form-control" component={renderInput} required name="email" type="email"
                         placeholder="Adresse e-mail*"/>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 form-group">
                  <Field required placeholder="Établissement *" className="form-control" name="high_school_name" component={props =>
                      <AutocompleteInput
                          {...props}
                          currentValue={{val: props.input.value}}
                          valChanged={value => props.input.onChange(value)}/>
                  }/>
                </div>
                <div className="col-sm-6 form-group">
                  <Field required className="form-control" name="high_school_type" component={renderSelect}>
                    <option value="">Type d'établissement</option>
                    <option value="0">Public</option>
                    <option value="1">Privé</option>
                    <option value="2">Mission</option>
                  </Field>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 form-group">
                  <Field required placeholder="Ville d'établissement" className="form-control" name="high_school_city" component={props =>
                      <AutocompleteInput
                          {...props}
                          currentValue={{val: props.input.value}}
                          valChanged={value => props.input.onChange(value)}/>
                  }/>
                </div>
                <div className="col-sm-6 form-group">
                  <Field className="form-control" component={renderInput} required name="niveau" type="text"
                         placeholder="Classe*"/>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-sm-6 form-group">
                  <Field required className="form-control" name="school_type" component={renderSelect}>
                    <option value="">Niveau</option>
                    <option value="Primaire">Primaire</option>
                    <option value="Collège">Collège</option>
                    <option value="Lycée">Lycée</option>
                  </Field>
                </div>
                <div className="col-sm-6 form-group">
                  <div className="phone-fields">
                    <Field className="phone-code-select" component={renderSelect} required name="phone_code">
                      {getPhoneCodes()}
                    </Field>
                    <Field className="phone-number-input" type="tel" component={renderInput} required name="phone" minLength="8" maxLength="12"
                           placeholder="Téléphone*" onChange={allowOnlyNumber}/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col form-group">
                  <div className="c-radio cndp">
                    <Field component={renderInput} id="cndp" required name="cndp" type="checkbox"/>
                    <label htmlFor="cndp">Conformément à la loi 09-08, vous disposez d'un droit d'accès, de rectification
                      et d'opposition au traitement de vos données personnelles. Ce traitement a été autorisé par la
                      Commission Nationale de protection de données Personnelles (CNDP).</label>
                  </div>
                </div>
              </div>
            </SlideAnimation>
            <div className="row">
              <div className="col d-flex justify-content-center">
                <button type="submit" className="btn purple">Suivant</button>
              </div>
            </div>
          </div>
        </form>
      </div>
  );
};

// Decorate with redux-form
FormSecondStep = reduxForm({
  form: 'admission_form', // a unique identifier for this form
  destroyOnUnmount: false,
  validate,
})(FormSecondStep);

// Decorate with connect to read form values
const selector = formValueSelector('admission_form');
FormSecondStep = connect(state => {
  const phoneCode = selector(state,
    'phone_code',
  );
  return {
    phoneCode,
  };
})(FormSecondStep);

export default FormSecondStep;
