import React from 'react'
import _ from "lodash";
import {Field, formValueSelector, reduxForm} from 'redux-form'
import validate from './validate'
import FormHeader from "../FormHeader";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import "../../styles/date-picker.scss";
import {connect} from "react-redux";
import SlideAnimation from "../animation/SlideAnimation";
import {renderInput} from "../../helpers/formHelpers";

let FormThirdStep = (props) => {
    const {handleFormSubmit, direction, previousPage, handleSubmit, testDate} = props;

    const MONTHS = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Aout',
        'Septembr',
        'Octobre',
        'Novembre',
        'Décembre',
    ];
    const WEEKDAYS_LONG = [
        'lundi',
        'mardi',
        'mercredi',
        'jeudi',
        'vendredi',
        'samedi',
        'dimanche',
    ];
    const WEEKDAYS_SHORT = ['Dim', 'Lu', 'Ma', 'Me', 'Jeu', 'Ven', 'Sam'];

    const maxTime = (testDate && (testDate.getDay() === 6 || testDate.getDay() === 0)) ? "13:00" : "20:00";

    const today = new Date();

    return (
        <div>
            <FormHeader title="Prise de contact"
                        description="Indiquez nous vos disponibilités pour un rendez-vous ou une demande de rappel."/>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="container mid-container">
                    <SlideAnimation direction={direction}>
                        <div className="row justify-content-md-center align-items-stretch mb-4">
                            <div className="col-sm-6 col-sm-offset-3">
                                <div className="c-card">
                                    <Field component={
                                        props => {
                                            const initialDate = (props.input.value instanceof Date) ? props.input.value : new Date();
                                            return (
                                                <DayPicker
                                                    initialMonth={initialDate}
                                                    disabledDays={[{before: today}]} locale="fr"
                                                    weekdaysLong={WEEKDAYS_LONG}
                                                    firstDayOfWeek={1} weekdaysShort={WEEKDAYS_SHORT}
                                                    months={MONTHS} selectedDays={testDate} onDayClick={day => {
                                                    if (day >= today) {
                                                        props.input.onChange(day);
                                                    }
                                                }}/>
                                            )
                                        }
                                    } name="test_admission"/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="mb-3 d-block d-sm-flex justify-content-center align-items-center">
                                <div className="pr-4">
                                    <label>Horaire</label>
                                </div>
                                <div>
                                    <Field component={renderInput} min="09:00" max={maxTime} className="time-input" id="time" required name="test_admission_time" type="time"/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="d-block d-sm-flex justify-content-center">
                                <div className="p-2">
                                    <label>Préférez-vous être contacté par ?</label>
                                </div>
                                <div className="p-2">
                                    <div className="c-radio">
                                        <Field component={renderInput} id="c-1" value="2" required name="contact_canal"
                                               type="radio"/>
                                        <label htmlFor="c-1">Par Téléphone</label>
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="c-radio">
                                        <Field component={renderInput} id="c-2" value="1" required name="contact_canal"
                                               type="radio"/>
                                        <label htmlFor="c-2">Par e-mail</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SlideAnimation>
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            <button type="button" onClick={previousPage} className="btn purple-border mr-4">Précédent
                            </button>
                            <button type="submit" className="btn purple">Envoyer</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

// Decorate with redux-form
FormThirdStep = reduxForm({
    form: 'admission_form', // a unique identifier for this form
    destroyOnUnmount: false,
    validate
})(FormThirdStep);

// Decorate with connect to read form values
const selector = formValueSelector('admission_form');
FormThirdStep = connect(state => {
    const testDate = selector(state, 'test_admission');
    return {
        testDate,
    }
})(FormThirdStep);

export default FormThirdStep;
