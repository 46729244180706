import React, {useState} from 'react';
import coursePathImg from '../images/course-path.svg';
import brochure from '../images/brochure.pdf';
import icon1 from '../images/course-path-icons/icon-1.svg';
import icon2 from '../images/course-path-icons/icon-2.svg';
import icon3 from '../images/course-path-icons/icon-3.svg';
import icon4 from '../images/course-path-icons/icon-4.svg';
import icon5 from '../images/course-path-icons/icon-5.svg';
import icon6 from '../images/course-path-icons/icon-6.svg';
import icon7 from '../images/course-path-icons/icon-7.svg';

const CoursePath = () => {
    const [activeItem, setActiveItem] = useState(1);
    return (
        <div className="course-path">
            {/*<div className="text-center pt-4 pb-4 text-white">*/}
            {/*    <a href={brochure} download className="btn purple btn-download">Télécharger la brochure</a>*/}
            {/*</div>*/}
            <div className="course-path--container">
                <img className="course-path--bg" src={coursePathImg} alt="Course path"/>
                <div className={`course-path--icon ${activeItem === 1 ? 'active' : ''}`}  id="icon1" onClick={() => {
                    setActiveItem(1)
                }}>
                    <span>Coaching scolaire</span>
                    <img src={icon1} alt="icon1"/>
                </div>
                <div className={`course-path--icon ${activeItem === 2 ? 'active' : ''}`}  id="icon2" onClick={() => {
                    setActiveItem(2)
                }}>
                    <span>Cours particuliers en petits groupes</span>
                    <img src={icon2} alt="icon2"/>
                </div>
                <div className={`course-path--icon ${activeItem === 3 ? 'active' : ''}`}  id="icon3" onClick={() => {
                    setActiveItem(3)
                }}>
                    <span> Cours particuliers individuels</span>
                    <img src={icon3} alt="icon3"/>
                </div>
                <div className={`course-path--icon ${activeItem === 4 ? 'active' : ''}`}  id="icon4" onClick={() => {
                    setActiveItem(4)
                }}>
                    <span>L'aide aux devoirs</span>
                    <img src={icon4} alt="icon4"/>
                </div>
                <div className={`course-path--icon ${activeItem === 5 ? 'active' : ''}`}  id="icon5" onClick={() => {
                    setActiveItem(5)
                }}>
                    <span>Séminaires</span>
                    <img src={icon5} alt="icon5"/>
                </div>
                <div className={`course-path--icon ${activeItem === 6 ? 'active' : ''}`}  id="icon6" onClick={() => {
                    setActiveItem(6)
                }}>
                    <span>Stages de vacances</span>
                    <img src={icon6} alt="icon6"/>
                </div>
                <div className={`course-path--icon ${activeItem === 7 ? 'active' : ''}`}  id="icon7" onClick={() => {
                    setActiveItem(7)
                }}>
                    <span>Objectif AUTONOMIE</span>
                    <img src={icon7} alt="icon7"/>
                </div>
            </div>
            <div className="course-path-desc">
                {activeItem === 1 &&
                <div className="course-path-item">
                    <h2>Coaching</h2>
                    <h3>Getting to the next level</h3>
                    <p>L’élève doit être pris dans sa globalité. Nos coachs, experts en éducation et nos professeurs
                        développent une approche personnalisée qui permet à celui-ci de révéler tout son potentiel et de
                        réussir son projet d’étude.<br/>
                        Nous participons à la construction d’une personnalité forte et résiliente pour favoriser la
                        réussite au-delà de l’école
                    </p>
                </div>
                }
                {activeItem === 2 &&
                <div className="course-path-item">
                    <h2>Cours particuliers en petits groupes</h2>
                    <h3>L’INTELLIGENCE COLLABORATIVE</h3>
                    <p>Les petits groupes permettent de donner une nouvelle dimension au travail personnel des élèves et
                        de créer des synergies dans l’émulation. Un maximum de 6 élèves est garanti pour une meilleure
                        optimisation du travail en groupe</p>
                </div>
                }
                {activeItem === 3 &&
                <div className="course-path-item">
                    <h2>Cours particuliers individuels</h2>
                    <h3>Du sur mesure</h3>
                    <p>Un duo professeur-élève est créé pour une approche entièrement dédiée. Le tandem progresse en
                        prenant en compte des axes d’évolution et des points forts pour une approche chirurgicale. Cette
                        formule permet une flexibilité totale dans le choix des créneaux et des matières</p>
                </div>
                }
                {activeItem === 4 &&
                <div className="course-path-item">
                    <h2>L’aide au devoir</h2>
                    <h3>la solution tant attendue</h3>
                    <p>Un environnement dédié au travail dans une ambiance studieuse et efficace pour accompagner les
                        collégiens dans le travail de tous les jours. Ils pourront développer des méthodes de travail et
                        d’organisation efficientes dans un cadre favorisant l’autonomie et l’accomplissement
                        personnel.</p>
                </div>
                }
                {activeItem === 5 &&
                <div className="course-path-item">
                    <h2>Séminaires</h2>
                    <p>Des interventions régulières seront programmées afin de présenter à nos élèves différents profils
                        professionnels. Ils pourront ainsi être en contact direct avec différents métiers et parcours
                        scolaires. Nos intervenants apporteront un aperçu du marché de l’emploi et les dernières
                        nouveautés sur les programmes de formation dans le monde.</p>
                </div>
                }
                {activeItem === 6 &&
                <div className="course-path-item">
                    <h2>Stages de vacances préparation aux examens</h2>
                    <p>Pendant les vacances scolaires, nos élèves auront la possibilité de suivre des stages permettant
                        de consolider leur connaissance, combler leurs lacunes et se préparer pour des examens
                        spécifiques (IELTS, TOEFL, etc.).
                        Nos stages seront l’occasion d’initier de nouvelles vocations grâce à de nouvelles formations
                        telles que le codage et la programmation informatique ou la gestion du stress.
                    </p>
                </div>
                }
                {activeItem === 7 &&
                <div className="course-path-item">
                    <h2>AUTONOMIE</h2>
                    <p>Chez XII l’autonomie de nos élèves est une valeur fondamentale. En plus de garantir une
                        amélioration des notes sur le court terme en renforçant les connaissances fondamentales, nous
                        mettons un focus particulier sur les « soft skills » des élèves afin de permettre une réussite
                        qui va au-delà du prochain Devoir Surveillé ou même du Baccalauréat. En choisissant XII vous
                        offrez à votre enfant les outils adéquats pour affronter le monde de demain.</p>
                </div>
                }
            </div>
        </div>
    );
};

export default CoursePath;