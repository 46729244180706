import React from 'react';
import image from '../images/lp-picture.png'
import imageMobile from '../images/page-image-mobile.png'
import {Spring} from "react-spring/renderprops-universal";
import {config} from "react-spring";

const LeftContent = () => {
    return (
        <div className="left-content">
            <Spring
                config={config.wobbly}
                from={{transform: 'translateY(-10%)'}}
                to={{transform: 'translate(0)'}}>
                {props => (
                    <div style={props} className="page-banner">
                        <picture style={props}>
                            <source media="(max-width: 768px)" srcSet={imageMobile}/>
                            <img className="main-image" src={image} alt="XII"/>
                        </picture>
                        <div className="page-banner-desc">
                            <h1>RÉUSSITE, MÉTHODE ET INNOVATION</h1>
                            <p>Chez XII, votre enfant progresse, s'épanouit et prend goût à l’apprentissage.
                                Chaque professeur, spécialiste dans son domaine, développe une méthode pédagogique qui
                                a pour objectif d'améliorer l'implication, la concentration et la motivation de l'élève.
                            </p>
                            <p>L'élève s'imprègne quotidiennement de la matière enseignée grâce à des activités adaptées
                                à son profil, à réaliser chez lui ou dans notre centre. Il nourrit et consolide ses
                                capacités et ses connaissances par l'enthousiasme créé autour de ces exercices.</p>
                        </div>
                    </div>
                )}
            </Spring>
        </div>
    );
};

export default LeftContent;
