import React from 'react';
import videoBg from '../images/video-bg.svg';
import video from '../images/video.mp4';

const VideoDescription = () => {
    return (
        <div className="video-description">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        
                        <video controls className="video-description--video" src={video}></video>
                    </div>
                    <div className="col-md-6">
                        <h2>Chaque élève est unique. Son apprentissage le sera aussi</h2>
                        <p>Chez XII nous mettons l’autonomie de nos élèves au centre de notre objectif. Le renforcement
                            des connaissances, l’acquisition de compétences académiques et les outils développés lui
                            garantiront très tôt la réussite des examens scolaires. Nous participons à la construction
                            d’une personnalité forte et résiliente pour favoriser la réussite au-delà de l’école.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoDescription;