import React, {useState} from 'react';
import {Autocomplete} from '@react-google-maps/api';

const AutocompleteInput = (props) => {
  const onLoad = (autocomplete) => {
    // console.log('autocomplete: ', autocomplete);

    setAutocomplete(autocomplete);
  };

  const [autocomplete, setAutocomplete] = useState(null);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      props.valChanged(autocomplete.getPlace().name);
    }
    // else {
    //   console.log('Autocomplete is not loaded yet!')
    // }
  };

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}>
      <input value={props.currentValue} {...props} {...props.input}/>
    </Autocomplete>
  );
};

export default AutocompleteInput;
