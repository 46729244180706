import React from 'react';

const FormHeader = ({title, description}) => {
  return (
    <header className="form-header container">
      <h2 className="form-header--title">{title}</h2>
      <p className="form-header--desc" dangerouslySetInnerHTML={{__html: description}}/>
    </header>
  );
};

FormHeader.defaultProps = {
  description: "Renseignez ce formulaire afin que notre département des admissions prenne contact avec vous dans les plus brefs délais."
};

export default FormHeader;
