import React, {useEffect, useState} from 'react';
import {animated, useSpring} from "react-spring";
import FormHeader from "../FormHeader";
import {isMobile} from "../../helpers/formHelpers";

const SlideAnimation = ({children, direction, page}) => {
  const initTranslate = (direction === 'right') ? -70 : 70;
  const [translate, setTranslate] = useState(initTranslate);

  useEffect(() => {
    setTranslate(0);
  }, [page]);

  const animationStyle = useSpring({
    transform: `translate(${translate}px)`
  });
  return (
    <animated.div style={(isMobile()) ? null : animationStyle}>
      {children}
    </animated.div>
  );
};

FormHeader.defaultProps = {
  direction: "right"
};

export default SlideAnimation;
