import React from 'react'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import {allowOnlyLetters, allowOnlyNumber, getPhoneCodes, renderInput, renderSelect} from '../../helpers/formHelpers';
import 'react-day-picker/lib/style.css';
import FormHeader from "../FormHeader";
import SlideAnimation from "../animation/SlideAnimation";
import {connect} from "react-redux";
import validate from "./validate";


let FormFirstStep = (props) => {
  const {handleSubmit, direction, valid, previousPage } = props;

  const handleSubmitClick = (e) => {
    if (!valid) e.preventDefault();
  };

  return (
    <div>
      <FormHeader title="Formulaire parent"
                  description="Saisissez vos coordonnées, nous vous contacterons afin de discuter du projet d'accompagnement de votre enfant."/>
      <form onSubmit={handleSubmit} className="class">
        <div className="container small-container">
          <SlideAnimation direction={direction}>
            <div className="d-flex justify-content-center">
              <div className="p-2">
                <label className="c-purple">Civilité:</label>
              </div>
              <div className="p-2">
                <div className="c-radio">
                  <Field component={renderInput} id="mr" value="Mr" required name="first_parent_civility" type="radio"/>
                  <label htmlFor="mr">Mr.</label>
                </div>
              </div>
              <div className="p-2">
                <div className="c-radio">
                  <Field component={renderInput} id="mme" value="Mme" required name="first_parent_civility" type="radio"/>
                  <label htmlFor="mme">Mme</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group">
                <Field className="form-control" component={renderInput} required name="first_parent_last_name"
                       onChange={allowOnlyLetters} placeholder="Nom*"/>
              </div>
              <div className="col-sm-6 form-group">
                <Field className="form-control" component={renderInput} required name="first_parent_first_name"
                       onChange={allowOnlyLetters} placeholder="Prénom*"/>
              </div>
            </div>
            <div className="row">
              <div className="col form-group">
                <Field className="form-control" component={renderInput} required name="first_parent_email" type="email"
                       placeholder="Email*"/>
              </div>
            </div>
            <div className="row">
              <div className="col form-group">
                <div className="phone-fields">
                  <Field className="phone-code-select" component={renderSelect} required name="phone_code_parent">
                    {getPhoneCodes()}
                  </Field>
                  <Field className="phone-number-input" type="tel" component={renderInput} required name="first_parent_phone" minLength="8" maxLength="12"
                         placeholder="Téléphone*" onChange={allowOnlyNumber}/>
                </div>
              </div>
            </div>
            {/*<div className="row mb-4">*/}
            {/*  <div className="col-sm-8 form-group">*/}
            {/*    <Field className="form-control" component={renderInput} required name="address" type="text"*/}
            {/*           placeholder="Adresse"/>*/}
            {/*  </div>*/}
              {/*<div className="col-sm-4 form-group">*/}
                {/*<Field placeholder="Ville" className="form-control" name="city" component={props =>*/}
                {/*  <AutocompleteInput*/}
                {/*    {...props}*/}
                {/*    currentValue={{val: props.input.value}}*/}
                {/*    valChanged={value => props.input.onChange(value)}/>*/}
                {/*}/>*/}
                {/*<Field placeholder="Ville" className="form-control" name="city" component={renderInput}/>*/}
              {/*</div>*/}
            {/*</div>*/}
            {/*<div className="row">*/}
            {/*  <div className="col form-group">*/}
            {/*    <div className="c-radio cndp">*/}
            {/*      <Field component={renderInput} id="cndp" required name="cndp" type="checkbox"/>*/}
            {/*      <label htmlFor="cndp">Conformément à la loi 09-08, vous disposez d'un droit d'accès, de rectification*/}
            {/*        et d'opposition au traitement de vos données personnelles. Ce traitement a été autorisé par la*/}
            {/*        Commission Nationale de protection de données Personnelles (CNDP).</label>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </SlideAnimation>
          <div className="row">
            <div className="col d-flex justify-content-center">
              <button type="button" onClick={previousPage} className="btn purple-border mr-4">Précédent</button>
              <button onClick={handleSubmitClick} type="submit" className="btn purple">Suivant</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
};

// Decorate with redux-form
FormFirstStep = reduxForm({
  form: 'admission_form', // a unique identifier for this form
  destroyOnUnmount: false,
  validate,
})(FormFirstStep);

// Decorate with connect to read form values
const selector = formValueSelector('admission_form');
FormFirstStep = connect(state => {
  const phoneCode = selector(state,
      'phone_code',
  );
  return {
    phoneCode,
  };
})(FormFirstStep);

export default FormFirstStep;
